<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action + ' App'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Submit
        </a-button>
      </template>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              style="margin: unset"
              ref="appKey"
              label="appKey"
              prop="appKey"
            >
              <a-input
                v-model="form.appKey"
                @blur="
                  () => {
                    $refs.appKey.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset"
              ref="appName"
              label="appName"
              prop="appName"
            >
              <a-input
                v-model="form.appName"
                @blur="
                  () => {
                    $refs.appName.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset"
              ref="baseUrl"
              label="baseUrl"
              prop="baseUrl"
            >
              <a-input
                v-model="form.baseUrl"
                @blur="
                  () => {
                    $refs.baseUrl.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset"
              ref="dbname"
              label="dbname"
              prop="dbname"
            >
              <a-input
                v-model="form.dbname"
                @blur="
                  () => {
                    $refs.dbname.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item style="margin: unset" label="Hide Periode">
              <a-select v-model="form.hide_periode">
                <a-select-option value="true">Hidden</a-select-option>
                <a-select-option value="false">Showed</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset"
              ref="icon_url"
              label="Icon Url"
              prop="icon_url"
            >
              <a-input
                v-model="form.icon_url"
                @blur="
                  () => {
                    $refs.icon_url.onFieldBlur();
                  }
                "
              />
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <div
                    style="
                      width: 60px;
                      height: 60px;
                      border: 1px solid grey;
                      border-radius: 4px;
                    "
                  >
                    <center>
                      <img
                        v-if="form.icon_url === null"
                        src="./favicon.png"
                        alt=""
                        style="width: 50px; height: 50px; margin-top: 4px"
                      />
                      <img
                        v-else
                        :src="form.icon_url"
                        alt=""
                        style="width: 50px; height: 50px; margin-top: 4px"
                      />
                    </center>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <input
                    type="file"
                    ref="iconurlfileupload"
                    style="display: none"
                    accept="image/x-png"
                    @input="onFileChange"
                  />
                  <a-button class="btn-sm" @click="triggerTheUploadFile"
                    >or upload the image?</a-button
                  >
                  <!-- <a-button class="btn-sm" @click="deleteImgFile">Delete Image</a-button> -->
                </div>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import * as lou from '@/services/data/lou'

export default {
  name: 'editprofile',
  created() {
    // this.getMaster()
  },
  data() {
    // const validatePass = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (this.form.password_confirmation !== '') {
    //   //     this.$refs.ruleForm.validateField('checkPass')
    //   //   }
    //   //   callback()
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password'))
    //   } else {
    //     if (this.form.password_confirmation !== '') {
    //       this.$refs.ruleForm.validateField('checkPass')
    //     }
    //     callback()
    //   }
    //   // }
    // }
    // const validatePass2 = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (value !== this.form.password) {
    //   //     callback(new Error("Password doesn't match!"))
    //   //   } else {
    //   //     callback()
    //   //   }
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password again'))
    //   } else {
    //     if (value !== this.form.password) {
    //       callback(new Error("Password doesn't match!"))
    //     } else {
    //       callback()
    //     }
    //   }
    //   // }
    // }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      user: [],
      roletags: false,
      editdata: {},
      action: 'Create',
      form: {
        appKey: '',
        appName: '',
        baseUrl: '',
        dbname: '',
        hide_periode: 'true',
        icon_url: '',
      },
      iconurlfile: null,
      rules: {
        appKey: [{ required: true, message: 'Please input appKey!' }],
        appName: [{ required: true, message: 'Please input appName!' }],
        baseUrl: [{ required: true, message: 'Please input baseUrl!' }],
        dbname: [{ required: true, message: 'Please input dbname!' }],
        icon_url: [{ required: false, message: 'Please input url icon!' }],
      },
      loading: false,
      visible: false,
      datenow: null,
    }
  },
  methods: {
    onFileChange(e) {
      // console.log('e', e)
      const file = e.target.files[0]
      this.iconurlfile = file
      this.form.icon_url = URL.createObjectURL(file)
    },
    triggerTheUploadFile() {
      this.$refs.iconurlfileupload.click()
    },
    async deleteImgFile() {
      const storageRef = firebase.storage().ref()
      const desertRef = storageRef.child('gaji/' + this.iconurlfile.name)
      await desertRef.delete()
    },
    // async getMaster() {
    //   var resp = await lou.readMaster('id_role')
    //   this.datarole = resp.data
    // },
    userAppSelect(e) {
      // console.log('e', e)
      var temp = []
      for (let m = 0; m < e.length; m++) {
        const element = e[m]
        temp.push({ id_app: element })
      }
      // console.log('temp', temp)
      this.form.user_App = temp
    },
    async showModal(action, data = {}) {
      // console.log('data', data)
      this.editdata = data
      // console.log('resapplist.data', resapplist.data)
      if (action === 'Update') {
        this.form.id = data.id
        this.form.appKey = data.appKey
        this.form.appName = data.appName
        this.form.baseUrl = data.baseUrl
        this.form.dbname = data.dbname
        this.form.hide_periode = data.hide_periode === true ? 'true' : 'false'
        this.form.icon_url = data.icon_url
      } else {
        this.form.appKey = ''
        this.form.appName = ''
        this.form.baseUrl = ''
        this.form.dbname = ''
        this.form.hide_periode = 'true'
        this.form.icon_url = null
        this.iconurlfile = null
      }
      this.action = action
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log('this.iconurlfile', this.iconurlfile)
          if (this.iconurlfile) {
            const storageRef = firebase
              .storage()
              .ref(`gaji/${this.form.appKey}`)
              .put(this.iconurlfile)
            storageRef.on(
              'state_changed',
              (snapshot) => {
                this.uploadValue =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              },
              () => {
                // console.log(error.message)
              },
              () => {
                this.uploadValue = 100
                storageRef.snapshot.ref.getDownloadURL().then((url) => {
                  this.picture = url
                  this.proccessTheApi(formName, url)
                })
              },
            )
          } else {
            if (this.action === 'Update') {
              this.proccessTheApi(formName, this.form.icon_url)
            } else {
              this.proccessTheApi(formName)
            }
          }
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    async proccessTheApi(formName, url = null) {
      var fd = {
        appKey: this.form.appKey,
        appName: this.form.appName,
        baseUrl: this.form.baseUrl,
        dbname: this.form.dbname,
        hide_periode: this.form.hide_periode === 'true',
        icon_url: url,
      }
      if (this.action === 'Create') {
        await lou.createMaster('manage/app', fd, true, true)
      } else {
        fd.id = this.form.id
        // this.deleteTheFirebaseFile()
        await lou.updateMaster('manage/app', fd, true, true)
      }
      this.$parent.getData()
      this.visible = false
      this.resetForm(formName)
    },
    async deleteTheFirebaseFile() {
      const storageRef = firebase.storage().ref()
      const desertRef = storageRef.child('gaji/' + this.editdata.icon_url)
      await desertRef.delete()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style></style>
